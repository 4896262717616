<template>
  <div @click="play" class="video-viewer">
    <el-image :style="{ width, height }" :src="cover" fit="cover"></el-image>
    <div class="mask">
      <div class="play-icon">
        <svg
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="display:block;height:16px;width:16px;fill:#222222"
          aria-hidden="true"
          role="presentation"
          focusable="false"
        >
          <path
            d="M14.5 7.13398C15.1667 7.51888 15.1667 8.48113 14.5 8.86603L5.5 14.0622C4.83333 14.4471 4 13.966 4 13.1962L4 2.80385C4 2.03405 4.83333 1.55292 5.5 1.93782L14.5 7.13398Z"
          ></path>
        </svg>
      </div>
      <div class="title">{{ title }}</div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      :fullscreen="true"
      :destroy-on-close="true"
      :body-style="{ padding: '0px' }"
      @closed="videoShow = false"
    >
      <video
        v-if="videoShow"
        style="width:100vw; height: calc(100vh - 80px);"
        autoplay
        :src="url"
        controls
      ></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    cover: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      videoShow: false,
      dialogVisible: false,
    };
  },
  methods: {
    play() {
      this.dialogVisible = true;
      this.videoShow = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0;
  padding-top: 20px;
}

.video-viewer {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  font-size: 0;

  .el-image {
    border-radius: 6px;
    cursor: pointer;
  }

  .mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);

    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 48px;
      height: 48px;
      background-color: #ebebeb;
      border-radius: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 40px);
      font-size: 16px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.9);
      margin-top: 5px;
    }
  }
}
</style>
